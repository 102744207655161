<template>
    <svg
        width="97"
        height="34"
        viewBox="0 0 97 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M94.1719 24.9994V7.78809H96.9995V24.9994H94.1719Z"
            fill="#109179"
        />
        <path
            d="M75.8203 24.9994L82.9015 7.78809H84.8685L91.9006 24.9994H88.8517L83.3441 11.0582H84.3768L78.82 24.9994H75.8203ZM79.3118 21.6801V19.2213H88.4338V21.6801H79.3118Z"
            fill="#109179"
        />
        <path
            d="M67.0522 24.9993V18.1886C67.0522 17.4018 66.7981 16.7543 66.29 16.2462C65.7982 15.738 65.159 15.4839 64.3722 15.4839C63.8476 15.4839 63.3805 15.5987 62.9707 15.8282C62.5609 16.0576 62.2412 16.3773 62.0118 16.7871C61.7823 17.1969 61.6675 17.664 61.6675 18.1886L60.6103 17.5985C60.6103 16.6969 60.807 15.9019 61.2004 15.2135C61.5938 14.5086 62.1347 13.9595 62.8231 13.5661C63.528 13.1563 64.3148 12.9514 65.1836 12.9514C66.0687 12.9514 66.8555 13.1809 67.544 13.6399C68.2324 14.0824 68.7733 14.6644 69.1667 15.3856C69.5601 16.0904 69.7568 16.8281 69.7568 17.5985V24.9993H67.0522ZM58.9629 24.9993V13.1973H61.6675V24.9993H58.9629Z"
            fill="black"
        />
        <path
            d="M48.9512 24.9993V13.1973H51.6558V24.9993H48.9512ZM51.6558 18.4345L50.7215 17.9673C50.7215 16.4756 51.0493 15.2708 51.705 14.3529C52.377 13.4186 53.3851 12.9514 54.7293 12.9514C55.3194 12.9514 55.8521 13.058 56.3275 13.2711C56.8028 13.4841 57.2454 13.8284 57.6552 14.3037L55.8849 16.1232C55.6718 15.8937 55.4341 15.7298 55.1718 15.6315C54.9096 15.5331 54.6063 15.4839 54.2621 15.4839C53.5081 15.4839 52.8852 15.7216 52.3934 16.197C51.9017 16.6723 51.6558 17.4182 51.6558 18.4345Z"
            fill="black"
        />
        <path
            d="M39.629 25.2452C38.5635 25.2452 37.5964 24.9748 36.7276 24.4338C35.8752 23.8929 35.195 23.1635 34.6868 22.2455C34.1951 21.3112 33.9492 20.2703 33.9492 19.1229C33.9492 17.9591 34.1951 16.9182 34.6868 16.0003C35.195 15.066 35.8752 14.3283 36.7276 13.7874C37.5964 13.2301 38.5635 12.9514 39.629 12.9514C40.5305 12.9514 41.3255 13.1481 42.0139 13.5415C42.7188 13.9185 43.2761 14.4431 43.6859 15.1151C44.0957 15.7872 44.3006 16.5494 44.3006 17.4018V20.7949C44.3006 21.6472 44.0957 22.4094 43.6859 23.0815C43.2925 23.7536 42.7434 24.2863 42.0385 24.6797C41.3337 25.0567 40.5305 25.2452 39.629 25.2452ZM40.0715 22.6881C41.0714 22.6881 41.8746 22.3521 42.4811 21.68C43.104 21.008 43.4154 20.1474 43.4154 19.0983C43.4154 18.3935 43.2761 17.7706 42.9975 17.2297C42.7188 16.6887 42.3254 16.2707 41.8173 15.9757C41.3255 15.6642 40.7436 15.5085 40.0715 15.5085C39.4159 15.5085 38.834 15.6642 38.3258 15.9757C37.8341 16.2707 37.4407 16.6887 37.1456 17.2297C36.8669 17.7706 36.7276 18.3935 36.7276 19.0983C36.7276 19.8032 36.8669 20.426 37.1456 20.967C37.4407 21.5079 37.8341 21.9341 38.3258 22.2455C38.834 22.5406 39.4159 22.6881 40.0715 22.6881ZM43.2433 24.9993V21.8275L43.7105 18.9508L43.2433 16.0986V13.1973H45.948V24.9993H43.2433Z"
            fill="black"
        />
        <path
            d="M27.0363 25.2452C25.8561 25.2452 24.7906 24.9829 23.8399 24.4584C22.9055 23.9175 22.1597 23.1799 21.6024 22.2455C21.0615 21.3112 20.791 20.2621 20.791 19.0983C20.791 17.9345 21.0615 16.8936 21.6024 15.9757C22.1433 15.0414 22.8728 14.3037 23.7907 13.7628C24.725 13.2219 25.7577 12.9514 26.8887 12.9514C27.987 12.9514 28.9541 13.2055 29.7901 13.7136C30.6424 14.2218 31.3063 14.9184 31.7817 15.8036C32.2734 16.6887 32.5193 17.6968 32.5193 18.8279C32.5193 19.0246 32.5029 19.2294 32.4701 19.4425C32.4537 19.6392 32.4209 19.8605 32.3718 20.1064H22.6843V17.8935H30.9703L29.9622 18.7787C29.9294 18.0574 29.7901 17.4509 29.5442 16.9592C29.2983 16.4674 28.9459 16.0904 28.4869 15.8282C28.0444 15.5659 27.4952 15.4348 26.8396 15.4348C26.1511 15.4348 25.5528 15.5823 25.0447 15.8773C24.5365 16.1724 24.1431 16.5904 23.8645 17.1313C23.5858 17.6558 23.4465 18.2869 23.4465 19.0246C23.4465 19.7622 23.594 20.4097 23.8891 20.967C24.1841 21.5243 24.6021 21.9587 25.143 22.2701C25.6839 22.5652 26.3068 22.7127 27.0117 22.7127C27.6182 22.7127 28.1755 22.6061 28.6836 22.3931C29.2082 22.18 29.6507 21.8685 30.0114 21.4587L31.7325 23.2044C31.1588 23.8765 30.4621 24.3846 29.6426 24.7289C28.823 25.0731 27.9542 25.2452 27.0363 25.2452Z"
            fill="black"
        />
        <path
            d="M0 24.9994V7.78809H2.82757V16.3937V24.9994H0ZM1.94242 24.9994V22.4177H10.9415V24.9994H1.94242Z"
            fill="black"
        />
        <path
            d="M15.4758 20.9433C14.935 20.9433 14.4394 20.8156 13.9888 20.5603C13.5532 20.2899 13.2002 19.9369 12.9299 19.5014C12.6745 19.0508 12.5469 18.5551 12.5469 18.0144C12.5469 17.1883 12.8323 16.4899 13.403 15.9191C13.9738 15.3484 14.6647 15.063 15.4758 15.063C16.3169 15.063 17.0228 15.3484 17.5936 15.9191C18.1643 16.4899 18.4497 17.1883 18.4497 18.0144C18.4497 18.8405 18.1643 19.5389 17.5936 20.1097C17.0228 20.6654 16.3169 20.9433 15.4758 20.9433Z"
            fill="#109179"
        />
    </svg>
</template>
